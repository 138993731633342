


// import React, { useEffect, useState } from "react";
// import { Button, Grid, Box, Typography, CircularProgress, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
// import { useNavigate, useParams } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { addStuff } from '../../../redux/userRelated/userHandle';
// import { underControl } from '../../../redux/userRelated/userSlice';
// import Popup from '../../../components/Popup';

// const SubjectForm = () => {
//     const [subjects, setSubjects] = useState([{ subName: "", subCode: "", sessions: "" }]);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const params = useParams();

//     const userState = useSelector(state => state.user);
//     const { status, currentUser, response, error } = userState;

//     const sclassName = params.id;
//     const adminID = currentUser._id;
//     const address = "Subject";

//     const [showPopup, setShowPopup] = useState(false);
//     const [message, setMessage] = useState("");
//     const [loader, setLoader] = useState(false);

//     const subjectNames = ["English", "Maths", "Economics", "Social Studies", "Physics", "Chemistry", "Literature", "Government", "CICVS",];
//     const subjectCodes = ["ENG", "MTH", "ECO", "SOS", "PHY", "CHE", "LIT", "GOVT"];
//     const sessions = ["2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];

//     const handleSubjectNameChange = (index) => (event) => {
//         const newSubjects = [...subjects];
//         newSubjects[index].subName = event.target.value;
//         setSubjects(newSubjects);
//     };

//     const handleSubjectCodeChange = (index) => (event) => {
//         const newSubjects = [...subjects];
//         newSubjects[index].subCode = event.target.value;
//         setSubjects(newSubjects);
//     };

//     const handleSessionsChange = (index) => (event) => {
//         const newSubjects = [...subjects];
//         newSubjects[index].sessions = event.target.value;
//         setSubjects(newSubjects);
//     };

//     const handleAddSubject = () => {
//         setSubjects([...subjects, { subName: "", subCode: "", sessions: "" }]);
//     };

//     const handleRemoveSubject = (index) => () => {
//         const newSubjects = [...subjects];
//         newSubjects.splice(index, 1);
//         setSubjects(newSubjects);
//     };

//     const fields = {
//         sclassName,
//         subjects: subjects.map((subject) => ({
//             subName: subject.subName,
//             subCode: subject.subCode,
//             sessions: subject.sessions,
//         })),
//         adminID,
//     };

//     const submitHandler = (event) => {
//         event.preventDefault();
//         setLoader(true);
//         dispatch(addStuff(fields, address));
//     };

//     useEffect(() => {
//         if (status === 'added') {
//             navigate("/Admin/subjects");
//             dispatch(underControl());
//             setLoader(false);
//         }
//         else if (status === 'failed') {
//             setMessage(response);
//             setShowPopup(true);
//             setLoader(false);
//         }
//         else if (status === 'error') {
//             setMessage("Network Error");
//             setShowPopup(true);
//             setLoader(false);
//         }
//     }, [status, navigate, error, response, dispatch]);

//     return (
//         <form onSubmit={submitHandler}>
//             <Box mb={2}>
//                 <Typography variant="h6">Add Subjects</Typography>
//             </Box>
//             <Grid container spacing={2}>
//                 {subjects.map((subject, index) => (
//                     <React.Fragment key={index}>
//                         <Grid item xs={6}>
//                             <FormControl fullWidth>
//                                 <InputLabel>Subject Name</InputLabel>
//                                 <Select
//                                     value={subject.subName}
//                                     onChange={handleSubjectNameChange(index)}
//                                     label="Subject Name"
//                                     required
//                                 >
//                                     {subjectNames.map((name) => (
//                                         <MenuItem key={name} value={name}>{name}</MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                         </Grid>
//                         <Grid item xs={4}>
//                             <FormControl fullWidth>
//                                 <InputLabel>Subject Code</InputLabel>
//                                 <Select
//                                     value={subject.subCode}
//                                     onChange={handleSubjectCodeChange(index)}
//                                     label="Subject Code"
//                                     required
//                                 >
//                                     {subjectCodes.map((code) => (
//                                         <MenuItem key={code} value={code}>{code}</MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                         </Grid>
//                         <Grid item xs={4}>
//                             <FormControl fullWidth>
//                                 <InputLabel>Sessions</InputLabel>
//                                 <Select
//                                     value={subject.sessions}
//                                     onChange={handleSessionsChange(index)}
//                                     label="Sessions"
//                                     required
//                                 >
//                                     {sessions.map((session) => (
//                                         <MenuItem key={session} value={session}>{session}</MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                         </Grid>
//                         <Grid item xs={6}>
//                             <Box display="flex" alignItems="flex-end">
//                                 {index === 0 ? (
//                                     <Button
//                                         variant="outlined"
//                                         color="primary"
//                                         onClick={handleAddSubject}
//                                     >
//                                         Add Subject
//                                     </Button>
//                                 ) : (
//                                     <Button
//                                         variant="outlined"
//                                         color="error"
//                                         onClick={handleRemoveSubject(index)}
//                                     >
//                                         Remove
//                                     </Button>
//                                 )}
//                             </Box>
//                         </Grid>
//                     </React.Fragment>
//                 ))}
//                 <Grid item xs={12}>
//                     <Box display="flex" justifyContent="flex-end">
//                         <Button variant="contained" color="primary" type="submit" disabled={loader}>
//                             {loader ? (
//                                 <CircularProgress size={24} color="inherit" />
//                             ) : (
//                                 'Save'
//                             )}
//                         </Button>
//                     </Box>
//                 </Grid>
//                 <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
//             </Grid>
//         </form>
//     );
// }

// export default SubjectForm;

// const styles = {
//     inputField: {
//         '& .MuiInputLabel-root': {
//             color: '#838080',
//         },
//         '& .MuiOutlinedInput-notchedOutline': { 
//             borderColor: '#838080',
//         },
//     },
// };


import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addStuff } from "../../../redux/userRelated/userHandle";
import { underControl } from "../../../redux/userRelated/userSlice";
import Popup from "../../../components/Popup";

const SubjectForm = () => {
  const [subjects, setSubjects] = useState([{ subName: "", subCode: "", sessions: "" }]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const userState = useSelector((state) => state.user);
  const { status, currentUser, response, error } = userState;

  const sclassName = params.id;
  const adminID = currentUser._id;
  const address = "Subject";

  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const subjectNames = [
    "English Language",
    "Mathematics",
    "Biology",
    "Chemistry",
    "Physics",
    "Agricultural Science",
    "Basic Science",
    "Health Science",
    "Economics",
    "Geography",
    "Government",
    "quantitative reason",
    "verbal reason",
    "Civic Education",
    "Social Studies",
    "Literature in English",
    "History",
    "Christian Religious Studies (CRS)",
    "Islamic Religious Studies (IRS)",
    "Fine Arts/Visual Arts",
    "Music",
    "Drama",
    "Cultural and Creative Arts (CCA)",
    "Home Economics",
    "Food and Nutrition",
    "Clothing and Textile",
    "Technical Drawing",
    "Business Studies",
    "Commerce",
    "Office Practice",
    "Marketing",
    "Entrepreneurship Education",
    "Computer Studies/ICT",
    "Data Processing",
    "Information Technology",
    "French",
    "Arabic",
    "German",
    "Spanish",
    "Yoruba",
    "Hausa",
    "Igbo",
    "Other Indigenous Languages",
    "Physical Education",
    "Health Education",
    "Financial Accounting",
    "Further Mathematics",
    "Animal Husbandry",
    "Fisheries",
    "Tourism",
    "Building Technology",
    "Electrical Installation",
    "Automobile Technology",
    "Woodwork",
    "Metalwork",
    "Catering",
    "Cosmetology",
  
  ];
  
  const subjectCodes = ["ENG", "MTH", "ECO", "SOS", "PHY", "CHE", "LIT", "GOVT"];
  const sessions = [
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
    

  ];

  const handleSubjectNameChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].subName = event.target.value;
    setSubjects(newSubjects);
  };

  const handleOtherSubjectNameChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].subName = event.target.value;
    setSubjects(newSubjects);
  };

  const handleSubjectCodeChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].subCode = event.target.value;
    setSubjects(newSubjects);
  };

  const handleSessionsChange = (index) => (event) => {
    const newSubjects = [...subjects];
    newSubjects[index].sessions = event.target.value;
    setSubjects(newSubjects);
  };

  const handleAddSubject = () => {
    setSubjects([...subjects, { subName: "", subCode: "", sessions: "" }]);
  };

  const handleRemoveSubject = (index) => () => {
    const newSubjects = [...subjects];
    newSubjects.splice(index, 1);
    setSubjects(newSubjects);
  };

  const fields = {
    sclassName,
    subjects: subjects.map((subject) => ({
      subName: subject.subName,
      subCode: subject.subCode,
      sessions: subject.sessions,
    })),
    adminID,
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setLoader(true);
    dispatch(addStuff(fields, address));
  };

  useEffect(() => {
    if (status === "added") {
      navigate("/Admin/subjects");
      dispatch(underControl());
      setLoader(false);
    } else if (status === "failed") {
      setMessage(response);
      setShowPopup(true);
      setLoader(false);
    } else if (status === "error") {
      setMessage("Network Error");
      setShowPopup(true);
      setLoader(false);
    }
  }, [status, navigate, error, response, dispatch]);

  return (
    <form onSubmit={submitHandler}>
      <Box mb={2}>
        <Typography variant="h6">Add Subjects</Typography>
      </Box>
      <Grid container spacing={2}>
        {subjects.map((subject, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              {subject.subName === "Others" ? (
                <TextField
                  fullWidth
                  label="Enter Subject Name"
                  value={subject.subName}
                  onChange={handleOtherSubjectNameChange(index)}
                  required
                />
              ) : (
                <FormControl fullWidth>
                  <InputLabel>Subject Name</InputLabel>
                  <Select
                    value={subject.subName}
                    onChange={(event) => {
                      if (event.target.value === "Others") {
                        handleOtherSubjectNameChange(index)({ target: { value: "" } });
                      } else {
                        handleSubjectNameChange(index)(event);
                      }
                    }}
                    label="Subject Name"
                    required
                  >
                    {subjectNames.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            {/* <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Subject Code</InputLabel>
                <Select
                  value={subject.subCode}
                  onChange={handleSubjectCodeChange(index)}
                  label="Subject Code"
                  required
                >
                  {subjectCodes.map((code) => (
                    <MenuItem key={code} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item xs={4}>
  <FormControl fullWidth>
    <InputLabel></InputLabel>
    <TextField
      value={subject.subCode}
      onChange={handleSubjectCodeChange(index)}
      label="Subject Code"
      placeholder="Subject code e.g EGL 101"
      required
    />
  </FormControl>
</Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Sessions</InputLabel>
                <Select
                  value={subject.sessions}
                  onChange={handleSessionsChange(index)}
                  label="Sessions"
                  required
                >
                  {sessions.map((session) => (
                    <MenuItem key={session} value={session}>
                      {session}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="flex-end">
                {index === 0 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddSubject}
                  >
                    Add Subject
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveSubject(index)}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit" disabled={loader}>
              {loader ? <CircularProgress size={24} color="inherit" /> : "Save"}
            </Button>
          </Box>
        </Grid>
        <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
      </Grid>
    </form>
  );
};

export default SubjectForm;
