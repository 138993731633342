import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Box, Button } from '@mui/material';
import styled from 'styled-components';
import Students from "../assets/sch.webp";
import s from "../assets/im.webp"
import { LightPurpleButton } from '../components/buttonStyles';

const Homepage = () => {
  return (
    <StyledContainer>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <StyledImageContainer>
            <img src={s} alt="students" style={{ height:'100%', width: '100%', borderRadius: '15px', boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)' }} />
          </StyledImageContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper elevation={3}>
            <StyledTitle>
              Welcome to
              <br />
              ESchools Management
              <br />
              Software
            </StyledTitle>
            <StyledText>
              Streamline school management, class organization, and add students and faculty.
              Seamlessly track attendance, assess performance, and provide feedback.
              Access records, view marks, and communicate effortlessly.
            </StyledText>
            <StyledBox>
              <StyledLink to="/choose">
                <LightPurpleButton variant="contained" fullWidth>
                  Login
                </LightPurpleButton>
              </StyledLink>
              <StyledLink to="https://eschoolconnect.ng/">
                <StyledGuestButton variant="outlined" fullWidth>
                  Homepage
                </StyledGuestButton>
              </StyledLink>
              <StyledText>
                Don't have an account?{' '}
                <Link to="/Adminregister" style={{ color: "green", fontWeight: 'bold' }}>
                  Sign up
                </Link>
              </StyledText>
            </StyledBox>
          </StyledPaper>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Homepage;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f3f4f6, #e2e8f0);  // Light gradient background
  padding: 20px;
`;

const StyledPaper = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledImageContainer = styled(Box)`
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

const StyledTitle = styled.h1`
  font-size: 2.5rem;
  color: green;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.4;
  text-align: center;
  margin: 20px 0;
`;

const StyledText = styled.p`
  color: #555;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.5px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledGuestButton = styled(Button)`
  color: green;
  border-color: #7f56;
  font-weight: bold;
  transition: all 0.3s ease;
  &:hover {
    background-color: #7f56;
    color: black;
  }
`;

